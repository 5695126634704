import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sideBarOpen: false,
    loader: false,
    notificationOpen: false,
    pageTitle: '',
    notificationCount: 0
  },
  getters: {
    sideBarOpen: state => {
      return state.sideBarOpen
    },
    loaderStatus: state => {
      return state.loader
    },
    notificationOpen: state => {
      return state.notificationOpen
    },
    pageTitle: state => {
      return state.pageTitle
    },
    notificationCount: state => {
      return state.notificationCount
    },
  },
  mutations: {
    toggleSidebar (state) {
      state.sideBarOpen = !state.sideBarOpen
    },
    toggleLoader (state) {
      state.loader = !state.loader
    },
    toggleNotification (state) {
      state.notificationOpen = !state.notificationOpen
    },
    setPageTitle (state,{title}) {
      state.pageTitle = title
    },
    setNotificationCount (state,{count}) {
      state.notificationCount = count
    },
  },
  actions: {
    toggleSidebar(context) {
      context.commit('toggleSidebar')
    },
    toggleLoader(context) {
      context.commit('toggleLoader')
    },
    toggleNotification (context) {
      context.commit('toggleNotification')
    },
    setPageTitle(context,title) {
      context.commit('setPageTitle',{title})
    },
    setNotificationCount(context,count) {
      context.commit('setNotificationCount',{count})
    },
  },
})
