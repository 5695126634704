import Vue from 'vue'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import { App, plugin } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress/src'
import VueAxios from 'vue-axios';
import axios from 'axios';
import moment from 'moment'

import store from './Store'

/*pusher code implement START*/
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true
});
/** Pusher code END**/

Vue.use(VueAxios, axios);
window.moment = moment
Vue.prototype.moment = moment;
Vue.config.productionTip = false
Vue.mixin({ methods: { route: window.route } })
Vue.use(plugin)
Vue.use(PortalVue)
Vue.use(VueMeta)

InertiaProgress.init({
  // The delay after which the progress bar will
  // appear during navigation, in milliseconds.
  delay: 250,

  // The color of the progress bar.
  color: '#29d',

  // Whether to include the default NProgress styles.
  includeCSS: true,

  // Whether the NProgress spinner will be shown.
  showSpinner: true,
})

const el = document.getElementById('app')

new Vue({
  metaInfo() {
    let page = JSON.parse(el.dataset.page)
    var page_props_settings_app_name = page.props && page.props.settings && page.props.settings.app_name ? page.props.settings.app_name : "ABC Interiors";
    var page_props_settings_favicon =  page.props && page.props.settings && page.props.settings.favicon ? page.props.settings.favicon : "fav.png";
    return {
      titleTemplate: title => (title ? `${title} - ${page_props_settings_app_name}` : page_props_settings_app_name),
      link: [
        {
          rel: 'shortcut icon',
          href: `/images/${page_props_settings_favicon}`,
        },
      ],
    }
  },
  render: h =>
    h(App, {
      props: {
        initialPage: JSON.parse(el.dataset.page),
        resolveComponent: name => import(`@admin/Pages/${name}`).then(module => module.default),
      },
    }),
  store,
}).$mount(el)
